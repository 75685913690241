import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import korpa from "../photos/korpa.png";
import glovo from "../photos/glovo.png";
import website from "../photos/website.png";
import instagram from "../photos/instagram.png";
import googleReviews from "../photos/google-reviews.png";
import tripAdvisor from "../photos/trip-advisor.png";
import CategoryList from "../components/CategoryList";
import arrow from "../photos/strelica.svg";
import { useState, useContext, useEffect } from "react";
import PlaceContext from "../contexts/PlaceContext";

export default function CategoryPage() {
  const { place, setPlaceId, mainCategories } = useContext(PlaceContext);
  const params = useParams();

  useEffect(() => {
    setPlaceId(params.id);
  }, []);

  let lang = useState(localStorage.getItem("dataKey") || []);
  lang = lang[0];

  const navigate = useNavigate();

  const Container = styled.div`
    b,
    p {
      ${({ font }) => font && `font-family: ${font};`}
    }
  `;

  return (
    <Container className="menuContainer" font={place.font}>
      <div
        style={{
          backgroundImage: `url(${place.background_image})`,
          backgroundSize: "cover",
          display: "flex",
          position: "relative",
          alignItems: "center",
          justifyContent: "center",
          width: "100vw",
          maxWidth: "480px",
          height: "35vh",
          backgroundPositionY: "center",
        }}
      >
        <div
          style={{
            height: "35vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            style={{ maxWidth: "60%", marginBottom: "10vh" }}
            src={place.image}
            alt="placeImage"
          ></img>
        </div>
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: `${place.background_color}`,
          minHeight: "75vh",
        }}
        className="onlineMenu"
      >
        <img
          src={arrow}
          alt="arrow"
          className="arrowLeft"
          style={{
            position: "absolute",
            left: "5%",
            top: "30px",
            width: "30px",
            height: "35px",
            cursor: "pointer",
          }}
          onClick={() => navigate(-1)}
        ></img>
        <div font={place.font}>
          {/* This needs to be refactored to use the correct language from translate file */}
          <p font={place.font} className="mt-4 mb-0">
            {(() => {
              if (lang === "bs") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Dobro došli u {place.name_bs}
                  </span>
                );
              } else if (lang === "rs") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Dobro došli u {place.name_rs}
                  </span>
                );
              } else if (lang === "hr") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Dobro došli u {place.name_hr}
                  </span>
                );
              } else if (lang === "me") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Dobro došli u {place.name_me}
                  </span>
                );
              } else if (lang === "en") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Welcome to {place.name_en}
                  </span>
                );
              } else if (lang === "de") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Willkommen bei {place.name_de}
                  </span>
                );
              } else if (lang === "at") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Willkommen bei {place.name_at}
                  </span>
                );
              } else if (lang === "es") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Bienvenido a {place.name_es}
                  </span>
                );
              } else if (lang === "fr") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Bienvenue à {place.name_fr}
                  </span>
                );
              } else if (lang === "it") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Benvenuti a {place.name_it}
                  </span>
                );
              } else if (lang === "tr") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    {" "}
                    {place.name_tr} hoş geldiniz
                  </span>
                );
              } else if (lang === "sa") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    {" "}
                    {place.name_sa} مرحباً
                  </span>
                );
              } else if (lang === "sr") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Добродошли у {place.name_sr}
                  </span>
                );
              } else if (lang === "al") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Mirë se vini në {place.name_al}
                  </span>
                );
              } else if (lang === "sl") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Dobrodošli v {place.name_sl}
                  </span>
                );
              } else if (lang === "nl") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Welkom bij {place.name_nl}
                  </span>
                );
              } else if (lang === "en-us") {
                return (
                  <span style={{ color: place.color_1 }} font={place.font}>
                    Welcome to {place.name_en_us}
                  </span>
                );
              }
            })()}
          </p>
          <b>
            <p
              className="mb-4"
              style={{ color: place.color_1, fontSize: "30px" }}
            >
              ONLINE MENU
            </p>
          </b>
        </div>
        <div className="categoryList">
          <CategoryList place={place} mainCategories={mainCategories} />

          <div className="icons-homepage">
            {place.instagram_url && (
              <a href={place.instagram_url} target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram" className="icons" />
              </a>
            )}
            {place.client_url && (
              <a href={place.client_url} target="_blank" rel="noreferrer">
                <img src={website} alt="website" className="icons" />
              </a>
            )}
            {place.korpa_url && (
              <a href={place.korpa_url} target="_blank" rel="noreferrer">
                <img src={korpa} alt="korpa" className="icons"></img>
              </a>
            )}
            {place.glovo_url && (
              <a href={place.glovo_url} target="_blank" rel="noreferrer">
                <img src={glovo} alt="glovo" className="icons"></img>
              </a>
            )}
            {place.trip_advisor_url && (
              <a href={place.trip_advisor_url} target="_blank" rel="noreferrer">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={tripAdvisor}
                  alt="trip advisor"
                ></img>
              </a>
            )}
            {place.google_reviews_url && (
              <a
                href={place.google_reviews_url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={googleReviews}
                  alt="google reviews"
                ></img>
              </a>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

